import React from "react";
import { SettingPage } from "../../../models/pageSettings";

type SideNavProps = {
  active: string;
  settings: SettingPage[];
  handleActiveSection: (section: string) => void;
};
const SideNav: React.FC<SideNavProps> = ({
  active,
  settings,
  handleActiveSection,
}) => {
  return (
    <div className="list-group transparent">
      {settings.map((setting) => (
        <div className="list-group-item-container">
          <button
            type="button"
            onClick={() => handleActiveSection(setting.id)}
            aria-current="true"
            key={setting.id}
            className={`btn-sm list-group-item list-group-item-action py-2 py-md-1 ${
              setting.id === active ? "active" : ""
            } `}
          >
            <i className={`${setting.icon} fa-fw mr-2`} aria-hidden="true"></i>
            {renderName(setting.name)}
          </button>
          {setting.submenu && setting.submenu.length > 0 && (
            <div className="list-group list-group-flush pl-4">
              {setting.submenu.map((subPage) => (
                <button
                  type="button"
                  onClick={() => handleActiveSection(subPage.path)}
                  key={`subPage_${subPage.id}`}
                  className={`btn-sm list-group-item list-group-item-action py-2 py-md-1 ${
                    subPage.id === active ? "active" : ""
                  } `}
                >
                  <i
                    className={`${subPage.icon} fa-fw mr-2`}
                    aria-hidden="true"
                  ></i>
                  {renderName(subPage.name)}
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function renderName(string: string) {
  ///split string into array and the rest of the string
  const [first] = string.split(" ");
  return (
    <span>
      <span className="d-md-none">{first}</span>
      <span className="xs_hidden">{string}</span>
    </span>
  );
}

export default SideNav;
