import { useMutation, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import React, { useEffect, useState } from "react";
import { ClassItem } from "../../../models/classes";
import "react-loading-skeleton/dist/skeleton.css";
import { IconJumbotron } from "../../common/icons";
import { GET_CLASSES_LMS, UPDATE_INTEGRATION } from "./queries";
import { AppIntegration } from "../../../models/apps";

type QueryResult = {
  classes: ClassItem[];
  userIntegrations: AppIntegration[];
};
const ClassesPage: React.FC = () => {
  const [classes, setClasses] = useState<ClassItem[]>([]);
  const [apps, setApps] = useState<AppIntegration | null>();
  const { data, loading } = useQuery<QueryResult>(GET_CLASSES_LMS);
  const [updateIntegration, { loading: loadingupdate }] =
    useMutation(UPDATE_INTEGRATION);

  useEffect(() => {
    if (data) {
      setApps(data.userIntegrations[0]);
      const set_classes = data.classes.filter((c) => c.import_lms !== null);
      setClasses(set_classes);
    }
  }, [data]);

  const handleSyncChange = (id: string) => {
    const newClasses = [...classes];
    const index = newClasses.findIndex((c) => c.value === id);
    newClasses[index].import_lms = !newClasses[index].import_lms;
    setClasses(newClasses);
  };

  const handleUpdate = async () => {
    if (loadingupdate || !apps) {
      return;
    }
    const classesToImport = classes
      .filter((c) => c.import_lms)
      .map((c) => c.value);
    updateIntegration({
      variables: {
        app_id: apps.id,
        import_students: true,
        imported_classes: classesToImport,
      },
    });
  };

  return (
    <div>
      <h1>Imported Classes</h1>
      <p>Select the classes you want to import from your LMS.</p>
      <div className="row">
        <div className="col-md-8">
          <div className="list-group">
            {loading ? (
              <Skeleton count={5} height={40} />
            ) : classes.length > 0 ? (
              classes.map((classItem) => (
                <button
                  type="button"
                  key={`button_classItem${classItem.value}`}
                  className="list-group-item list-group-item-action "
                  aria-current="true"
                  onClick={() => handleSyncChange(classItem.value)}
                >
                  <i
                    className={`far ${
                      classItem.import_lms
                        ? "fa-check-square text-success"
                        : "fa-square text-muted"
                    } mr-2 `}
                  ></i>
                  {classItem.txt}
                </button>
              ))
            ) : (
              <IconJumbotron
                icon="far fa-users-slash"
                classes="mt-5"
                txt="No Classes Found"
                help_text="Looks like you don't have classes imported from your LMS."
                cta={{
                  txt: "Connect LMS aplication",
                  link: "/settings/applications",
                  classes: "btn-primary",
                }}
              ></IconJumbotron>
            )}
          </div>
        </div>
        <div className="col-md-4">
          <button
            type="button"
            className="btn btn-primary btn-block"
            aria-current="true"
            onClick={handleUpdate}
            disabled={loadingupdate || loading}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClassesPage;
