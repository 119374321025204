import React, { MouseEvent, useEffect, useMemo, useState, useRef } from "react";
// import CleverLogo from "../../assets/cleverlogo.jpeg";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { ErrorType } from "../../../models/errors";
import {
  SAVE_INTEGRATION,
  GET_INTEGRATIONS,
  UPDATE_INTEGRATION,
  DELETE_INTEGRATION,
} from "./queries";
import { AppIntegration } from "../../../models/apps";

const Applications: React.FC = () => {
  const query = useQueryURL();
  const history = useHistory();
  const location = useLocation();
  const [apps, setApps] = useState<AppIntegration[]>([]);
  const [error, setError] = useState<ErrorType | null>(null);
  const { data, refetch, loading } = useQuery(GET_INTEGRATIONS);
  const [
    saveIntegration,
    { error: integrationError, loading: loadingSave, data: dataSave },
  ] = useMutation(SAVE_INTEGRATION);
  const [updateIntegration, { loading: loadingupdate }] =
    useMutation(UPDATE_INTEGRATION);
  const [
    deleteIntegration,
    { data: dataRevoke, error: integrationDeleteError, loading: loadingRevoke },
  ] = useMutation(DELETE_INTEGRATION);

  useEffect(() => {
    const code = query.get("code");
    const error_url = query.get("error");
    const error_description = query.get("error_description");
    const stored_app_id = localStorage.getItem("app_id");
    localStorage.removeItem("app_id");
    if (code) {
      saveIntegration({
        variables: {
          user_token: code,
          app_id: stored_app_id,
        },
      });
      removeQueryParams();
    } else if (error_url && error_description) {
      const set_error: ErrorType = {
        message: error_description,
      };
      setError(set_error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  useEffect(() => {
    if (integrationError) {
      console.log("applications.tsx:44 | integrationError", integrationError);
      const set_error: ErrorType = {
        message: integrationError.graphQLErrors[0].message,
      };
      setError(set_error);
    } else if (integrationDeleteError) {
      console.log(
        "applications.tsx:44 | integrationError",
        integrationDeleteError
      );
      const set_error: ErrorType = {
        message: integrationDeleteError.graphQLErrors[0].message,
      };
      setError(set_error);
    }
  }, [integrationError, integrationDeleteError]);

  useEffect(() => {
    if (data) {
      setApps(data.userIntegrations);
    } else if (dataRevoke) {
      setApps(dataRevoke.deleteIntegration);
    }
  }, [data, dataRevoke]);

  useEffect(() => {
    if (dataSave) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSave]);

  const removeQueryParams = () => {
    // Use the URLSearchParams API to work with query strings
    const queryParams = new URLSearchParams(location.search);

    // Remove the query parameter you don't want
    queryParams.delete("code");

    // Update the URL without a page refresh
    history.push(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleEdlinkSignup = (app_id: string) => {
    localStorage.setItem("app_id", app_id);
    const loginUrl = `https://ed.link/sso/login?client_id=9aabc0db-fdeb-43e7-aadd-028467a96ac9&redirect_uri=${process.env.REACT_APP_URL}/settings/applications`;
    window.location.href = loginUrl;
  };
  const handleRevoke = async (app_id: string) => {
    if (loadingRevoke) {
      return;
    }
    deleteIntegration({
      variables: {
        app_id,
      },
    });
  };

  const handleUpdate = async (app_id: string) => {
    if (loadingupdate) {
      return;
    }
    updateIntegration({
      variables: {
        app_id,
        import_students: true,
      },
    });
  };

  return (
    <div className="setting_panel applications_panel">
      <h4>Applications</h4>
      <p>
        When you link any of these apps, we'll gather and keep your student
        information up to date from the learning systems (LMS) you're connected
        to. Connect all that apply.
      </p>
      <div className="list">
        {error ? (
          <div className="alert alert-danger">
            <p>{error.message}</p>
          </div>
        ) : null}
        <ul className="list-group apps_list">
          {!loading && !loadingRevoke && !loadingSave ? (
            apps.length ? (
              apps.map((integration: any) => (
                <AppItem
                  key={`AppItem${integration.id}`}
                  id={integration.id}
                  handleClick={handleEdlinkSignup}
                  handleRevoke={handleRevoke}
                  handleUpdate={handleUpdate}
                  active={integration.active}
                  imgage={integration.logo}
                  title={integration.name}
                  loadingupdate={loadingupdate}
                  subtitle={integration.description}
                  url={{
                    name: integration.company,
                    url: integration.url,
                  }}
                />
              ))
            ) : (
              <ConnectLMS />
            )
          ) : (
            <RenderSkeleton />
          )}
        </ul>
      </div>
    </div>
  );
};
type AppItemProps = {
  id: string;
  active: boolean;
  handleClick: any;
  handleRevoke: any;
  handleUpdate: any;
  imgage: string;
  loadingupdate: boolean;
  title: string;
  subtitle: string;
  url: {
    name: string;
    url: string;
  };
};
const AppItem: React.FC<AppItemProps> = ({
  id,
  active,
  handleClick,
  handleRevoke,
  handleUpdate,
  imgage,
  title,
  subtitle,
  loadingupdate,
  url,
}) => {
  const updateButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const button = updateButtonRef.current;
    if (button && !loadingupdate && active) {
      button.innerText = "Update";
      button.disabled = false;
    }
  }, [loadingupdate, active]);
  const runHandleUpdate = async (ev: MouseEvent<HTMLButtonElement>) => {
    if (loadingupdate || !updateButtonRef.current) {
      return;
    }
    // Update button text to "Updating.." and disable it
    updateButtonRef.current.innerText = "Updating...";
    updateButtonRef.current.disabled = true;

    handleUpdate(id);
  };
  return (
    <li className="list-group-item app_item ">
      <img src={imgage} alt={title} />
      <div>
        {title}{" "}
        {active ? (
          <small className="text-success">
            Connected <i className="fas fa-badge-check" aria-hidden="true"></i>
          </small>
        ) : null}{" "}
        <br />{" "}
        <small className="text-muted">
          {subtitle} · Owned by{" "}
          <a
            href={url.url}
            className="text-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            {url.name}
          </a>
          {active ? (
            <>
              {" "}
              ·{" "}
              <button
                className="btn btn-sm text-small text-danger m-0 p-0"
                onClick={() => handleRevoke(id)}
              >
                Revoke permissions
              </button>
            </>
          ) : null}
        </small>
      </div>
      <button
        onClick={active ? runHandleUpdate : () => handleClick(id)}
        ref={updateButtonRef}
        className={`btn ${
          active ? "btn-success" : "btn-outline-secondary"
        } btn-sm`}
      >
        {active ? "Update" : "Connect"}
      </button>
    </li>
  );
};

const ConnectLMS: React.FC = () => {
  const handleEdlinkSignup = (app_id: string) => {
    localStorage.setItem("app_id", app_id);
    const loginUrl = `https://ed.link/sso/login?client_id=9aabc0db-fdeb-43e7-aadd-028467a96ac9&redirect_uri=${process.env.REACT_APP_URL}/settings/applications`;
    window.location.href = loginUrl;
  };
  return (
    <div className="card">
      <div className="card-body text-center px-5">
        <div className="lms_imgs">
          {" "}
          <img
            className="img-fluid"
            src="https://flangoo-cdn.s3.us-east-2.amazonaws.com/canvaslogo.png"
            alt="canvaslogo"
          />
          <img
            className="img-fluid"
            src="https://flangoo-cdn.s3.us-east-2.amazonaws.com/schoology.png"
            alt="canvaslogo"
          />
          <img
            className="img-fluid"
            src="https://flangoo-cdn.s3.us-east-2.amazonaws.com/classroomlogo.svg"
            alt="Google Classroom"
          />
          {/* <img className="img-fluid" src={CleverLogo} alt="Clever logo" /> */}
        </div>
        <h5 className="text-dark mb-0 mt-3">Connect your LMS</h5>
        <br />{" "}
        <p className="text-muted ">
          To show you the available applications in your district or school, we
          need to connect to your LMS first.
        </p>
        <button
          onClick={() => handleEdlinkSignup("edlink")}
          className="btn btn-primary"
        >
          Connect your LMS
        </button>
      </div>
      <div className="card-footer">
        <p className="text-dark m-0 small">
          {" "}
          Google Classroom, Schoology, and Canvas require administrator
          configuration. Click{" "}
          <Link to="/lms_help" className="text-primary text_underline">
            here
          </Link>{" "}
          to learn more.
        </p>
      </div>
    </div>
  );
};

const RenderSkeleton: React.FC = () => {
  const Item = () => (
    <li className="list-group-item app_item ">
      <div className="row">
        <div className="col-2">
          <Skeleton height="70px" />
        </div>
        <div className="col-6">
          <Skeleton width="40%" />
          <br />{" "}
          <small className="text-muted">
            <Skeleton count={2} />
          </small>
        </div>
      </div>
    </li>
  );
  const items = [1, 2, 3];
  return (
    <div>
      {items.map((item) => (
        <Item key={`item${item}`} />
      ))}
    </div>
  );
};

function useQueryURL() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default Applications;
